import cls from 'classnames';
import { useState, useMemo } from 'react';
import { useStripe, useElements, CardElement, IbanElement } from '@stripe/react-stripe-js';
import { Col, Row, Form, Accordion, ProgressBar } from 'react-bootstrap';
import { TooltipIcon } from 'src/components/base/Tooltip';
import { PAYMENT_TYPE_CARD, PAYMENT_TYPE_SEPA, PAYMENT_TYPE_SOFORT, PAYMENT_TYPE_BANK, PAYMENT_TYPE_VBAN } from 'src/constants';
import classes from './PaymentInfo.module.scss';

const COUNTRIES_SEPA = ['de'];
const COUNTRIES_SOFORT = []; // ['at', 'be', 'de', 'it', 'nl', 'es']; REMOVE LATER
const COUNTRIES_BANK = ['de'];
const COUNTRIES_VBAN = []; // ['am']; //TODO: Armenia for test purposes

export const PaymentInfo = ({ registerNext, allowedTypes, data: { billing_details } }) => {
  const { name, email, address } = billing_details;

  // Custom styling can be passed as options when creating an Element.
  const IBAN_STYLE = {
    base: {
      color: '#32325d',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      },
      ':-webkit-autofill': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
      ':-webkit-autofill': {
        color: '#fa755a',
      },
    }
  };

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    // Elements can use a placeholder as an example IBAN that reflects
    // the IBAN format of your customer's country. If you know your
    // customer's country, we recommend that you pass it to the Element as the
    // placeholderCountry.
    placeholderCountry: address?.country?.toUpperCase() || 'DE',
    style: IBAN_STYLE,
  };

  const availableTypes = useMemo(() => {
    const types = allowedTypes ?? [PAYMENT_TYPE_CARD, PAYMENT_TYPE_SEPA, PAYMENT_TYPE_SOFORT, PAYMENT_TYPE_BANK, PAYMENT_TYPE_VBAN];
    return Object.fromEntries(types.filter(type =>
      (type === PAYMENT_TYPE_CARD) ||
      (type === PAYMENT_TYPE_SEPA && COUNTRIES_SEPA.includes(address?.country)) ||
      (type === PAYMENT_TYPE_SOFORT && COUNTRIES_SOFORT.includes(address?.country)) ||
      (type === PAYMENT_TYPE_BANK && COUNTRIES_BANK.includes(address?.country)) ||
      (type === PAYMENT_TYPE_VBAN && COUNTRIES_VBAN.includes(address?.country))
    ).map(type => [type, true]))
  }, [allowedTypes, address?.country]);

  const stripe = useStripe();
  const elements = useElements();

  const [progress, setProgress] = useState(0);
  const [recurring, setRecurring] = useState(true);
  const [errors, setErrors] = useState({});
  const [type, setType] = useState(Object.keys(availableTypes)[0]);

  registerNext(async (submit) => {
    const source = { type };

    if (type === PAYMENT_TYPE_CARD) {
      setProgress(100);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card', card: elements.getElement(CardElement), billing_details: { name, email, address },
      });
      setProgress(0);

      setErrors(error && { type: PAYMENT_TYPE_CARD, message: error.message });
      if (error) { return }

      Object.assign(source, { recurring, card: { payment_method_id: paymentMethod.id } });
    }

    else if (type === PAYMENT_TYPE_SEPA) {
      setProgress(100);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'sepa_debit', sepa_debit: elements.getElement(IbanElement), billing_details: { name, email },
      });
      setProgress(0);

      setErrors(error && { type: PAYMENT_TYPE_SEPA, message: error.message });
      if (error) { return }

      Object.assign(source, { recurring, sepa_debit: { payment_method_id: paymentMethod.id, country: address?.country, preferred_language: config.lang } });
    }

    else if (type === PAYMENT_TYPE_SOFORT) {
      Object.assign(source, { sofort: { country: address?.country, preferred_language: config.lang } });
    }

    submit(source);
  });

  return (
    <Form>
      <Row>
        <Col md={12}>
          <p>{__('Please fill your billing information into the form below, then click on to the next page where you can see your order summary before confirming the purchase.')}</p>

          <h5 className="pb-3">{__('Payment Options')}<a href="https://stripe.com/" target="_blank" className={cls(classes.stripeLogo, 'float-end')} /></h5>

          <Accordion defaultActiveKey={type} onSelect={setType}>

            {availableTypes[PAYMENT_TYPE_CARD] &&
              <Accordion.Item eventKey={PAYMENT_TYPE_CARD}>
                <Accordion.Header>{__('Credit Card')}</Accordion.Header>
                <Accordion.Body>

                  <Form.Group className="mt-1">
                    <Form.Control as={CardElement} isInvalid={errors?.type === PAYMENT_TYPE_CARD} />
                    <ProgressBar style={{ height: 5, opacity: progress === 0 ? 0 : 1 }} animated now={progress} variant="primary" />

                    <Form.Control.Feedback type="invalid">{errors?.message}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2">
                    <Form.Check inline type={'checkbox'} id="recurring" checked={recurring} onChange={event => setRecurring(event.target.checked)} label={__('Recurring payment (only credit card)')} />
                    <TooltipIcon>{__('When this box is ticked, your renewal payments will happen automatically in our system. You can change this setting in your profile at any time.')}</TooltipIcon>
                  </Form.Group>

                </Accordion.Body>
              </Accordion.Item>
            }

            {availableTypes[PAYMENT_TYPE_SEPA] &&
              <Accordion.Item eventKey={PAYMENT_TYPE_SEPA}>
                <Accordion.Header>{__('SEPA Direct Debit')}</Accordion.Header>
                <Accordion.Body>

                  <Form.Group className="mt-1">
                    <Form.Control as={IbanElement} options={IBAN_ELEMENT_OPTIONS} isInvalid={errors?.type === PAYMENT_TYPE_SEPA} />
                    <ProgressBar style={{ height: 5, opacity: progress === 0 ? 0 : 1 }} animated now={progress} variant="primary" />

                    <Form.Control.Feedback type="invalid">{errors?.message}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2">
                    <Form.Check inline type={'checkbox'} id="recurring" checked={recurring} onChange={event => setRecurring(event.target.checked)} label={__('Recurring payment')} />
                    <TooltipIcon>{__('When this box is ticked, your renewal payments will happen automatically in our system. You can change this setting in your profile at any time.')}</TooltipIcon>
                  </Form.Group>

                  {/* Display mandate acceptance text. */}
                  <div className="mandate-acceptance">{__('By providing your payment information and confirming this payment, you authorise (A) ALERT HOUSE ApS and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.')}</div>

                </Accordion.Body>
              </Accordion.Item>
            }

            {availableTypes[PAYMENT_TYPE_SOFORT] &&
              <Accordion.Item eventKey={PAYMENT_TYPE_SOFORT}>
                <Accordion.Header>{__('SOFORT')}</Accordion.Header>
                <Accordion.Body>
                  {__('Select this option in order to pay through Klarna. This payment method enables you to pay online by bank transfer. You will only need your usual online banking information (PIN, TAN). No additional registration is necessary.')}
                  <br/><br/>
                  {__('Please note that the payment is not received immediately, but usually after 2-3 working days. If your trap subscription has expired, it will only be renewed and the trap alarm(s) unblocked after we receive your payment.')}
                </Accordion.Body>
              </Accordion.Item>
            }

            {availableTypes[PAYMENT_TYPE_BANK] &&
              <Accordion.Item eventKey={PAYMENT_TYPE_BANK}>
                <Accordion.Header>{__('Bank transfer (+5 €)')}</Accordion.Header>
                <Accordion.Body>
                  {__('Select this option in order to pay through bank. Please note that the bank transfer option costs an additional 5 € in administration charge because we must find your payment and match it manually.')}
                  <br/><br/>
                  {__('Bank payments are usually received within 2-3 working days. If your trap subscription has expired, it will only be renewed and the trap alarm(s) unblocked after we receive your payment.')}
                </Accordion.Body>
              </Accordion.Item>
            }

            {availableTypes[PAYMENT_TYPE_VBAN] &&
              <Accordion.Item eventKey={PAYMENT_TYPE_VBAN}>
                <Accordion.Header>{__('VBAN transfer (+5 €)')}</Accordion.Header>
                <Accordion.Body>
                  {__('Select this option in order to pay through VBAN. Please note that the VBAN transfer option costs an additional 5 € in administration charge.')}
                  <br/><br/>
                  {__('Bank payments are usually received within 2-3 working days. If your trap subscription has expired, it will only be renewed and the trap alarm(s) unblocked after we receive your payment.')}
                </Accordion.Body>
              </Accordion.Item>
            }

          </Accordion>
        </Col>
      </Row>
    </Form>
  )
}
